import React from 'react';

const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/base/cards/Cards'));
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

const Jumbotrons = React.lazy(() => import('./views/base/jumbotrons/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'));
const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
const Navs = React.lazy(() => import('./views/base/navs/Navs'));
const Paginations = React.lazy(() => import('./views/base/paginations/Pagnations'));
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
const ProgressBar = React.lazy(() => import('./views/base/progress-bar/ProgressBar'));
const Switches = React.lazy(() => import('./views/base/switches/Switches'));

const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/buttons/brand-buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/buttons/button-dropdowns/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
const Charts = React.lazy(() => import('./views/charts/Charts'));
const Dashboard = React.lazy(() => import('./views/dashboard/DashboardHome'));
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
const Users = React.lazy(() => import('./views/users/Users'));
const User = React.lazy(() => import('./views/users/User'));

const Inversores = React.lazy(() => import('./views/inversores/Inversores'));
const InversoresDetail = React.lazy(() => import('./views/inversores/InversoresDetail'));
const InversoresEvents = React.lazy(() => import('./views/inversores/InversoresEvents'));
const Encoders = React.lazy(() => import('./views/encoders/Encoders'));
const EncoderDetail = React.lazy(() => import('./views/encoders/EncodersDetail'));
const Ups = React.lazy(() => import('./views/ups/Ups'));
const UpsDetail = React.lazy(() => import('./views/ups/UpsDetail'));
const Home = React.lazy(() => import('./views/home/Home'));
const HomeSchedule = React.lazy(() => import('./views/home/HomeSchedule'));
const HomeMachineLearning = React.lazy(() => import('./views/home/HomeMachineLearning'));

const Tg = React.lazy(() => import('./views/tg/Tg'));
const TgDetail = React.lazy(() => import('./views/tg/TgDetail'));
const Precipitador = React.lazy(() => import('./views/precipitador/Precipitador'));
const PrecipitadorDetail = React.lazy(() => import('./views/precipitador/PrecipitadorDetail'));
const RedeProfibus = React.lazy(() => import('./views/redeProfibus/RedeProfibus'));
const RedeProfibusDetail = React.lazy(() => import('./views/redeProfibus/RedeProfibusDetail'));
const Assets = React.lazy(() => import('./views/registration/Assets'));
const Repair = React.lazy(() => import('./views/repair_management/repair/index'));
const Equipment = React.lazy(() => import('./views/repair_management/equipment/index'));
const RawMaterial = React.lazy(() => import('./views/stamp_shop/raw_material/index'));
const CCM = React.lazy(() => import('./views/ccm/CCM'));
const CCMDetail = React.lazy(() => import('./views/ccm/CCMDetail'));
const Motor = React.lazy(() => import('./views/motor/Motor'));
const MotorDetail = React.lazy(() => import('./views/motor/MotorDetail'));

const Prensa = React.lazy(() => import('./views/prensa/Prensa'));
const PrensaDetail = React.lazy(() => import('./views/prensa/PrensaDetail'));

const Disjuntor = React.lazy(() => import('./views/disjuntor/Disjuntor'));
const DisjuntorDetail = React.lazy(() => import('./views/disjuntor/DisjuntorDetail'));

const Pump = React.lazy(() => import('./views/pump/Pump'));
const PumpDetail = React.lazy(() => import('./views/pump/PumpDetail'));

const Station = React.lazy(() => import('./views/station/Station'));
const StationDetail = React.lazy(() => import('./views/station/StationDetail'));

const routes = [
  { path: '/home', exact: true, name: 'Home' },
  { path: '/homeSchedule', exact: true, name: 'HomeSchedule' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/forms', name: 'Forms', component: BasicForms },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/ativos/detalhes/:id', name: 'Detalhes', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', componentt: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },

  { path: '/inversores', name: 'Inversores', component: Inversores },
  { path: '/inversorDetail/:id', name: 'Detalhes', component: InversoresDetail },
  { path: '/inversorEvent/:id', name: 'Eventos', component: InversoresEvents },
  { path: '/encoders/', name: 'Encoders', component: Encoders },
  { path: '/encoderDetail/:id', name: 'Detalhe', component: EncoderDetail },
  { path: '/ups/', name: 'Ups', component: Ups},
  { path: '/upsDetail/:id', name: 'Detalhe', component: UpsDetail},
  { path: '/home', name: 'Home', component: Home},
  { path: '/home-schedule', name: 'Agenda', component: HomeSchedule},
  { path: '/home-machineLearning', name: 'Anomalias', component: HomeMachineLearning},
  { path: '/tg', name: 'Turbo Gerador', component: Tg },
  { path: '/tgDetail/:id', name: 'Turbo Gerador', component: TgDetail },
  { path: '/precipitadores', name: 'Precipitador', component: Precipitador },
  { path: '/precipitadorDetail/:id', name: 'Precipitador', component: PrecipitadorDetail },
  { path: '/redeProfibus', name: 'Rede Profibus', component: RedeProfibus },
  { path: '/redeProfibusDetail/:id', name: 'Rede Profibus', component: RedeProfibusDetail },
  { path: '/registration/assets', name: 'Ativos', component: Assets },
  { path: '/repair/index', name: 'Gestão de Reparo', component: Repair },
  { path: '/equipment/index', name: 'Cadastro de Equipamento', component: Equipment },
  { path: '/raw_material/index', name: 'Estoque de Matéria Prima', component: RawMaterial },
  //{ path: '/ccm', name: 'CCM', component: CCM },
  //{ path: '/ccmDetail/:id', name: 'CCM', component: CCMDetail },
  //{ path: '/motor', name: 'Motor', component: Motor },
  //{ path: '/motorDetail/:id', name: 'Motor', component: MotorDetail }
  { path: '/prensa', name: 'Prensa', component: Prensa },
  { path: '/prensaDetail/:id', name: 'Prensa', component: PrensaDetail },
  { path: '/disjuntor', name: 'Disjuntor', component: Disjuntor },
  { path: '/disjuntorDetail/:id', name: 'Disjuntor', component: DisjuntorDetail },
  { path: '/pump', name: 'Pump', component: Pump },
  { path: '/pumpDetail/:id', name: 'Pump', component: PumpDetail },
  { path: '/station', name: 'Station', component: Station },
  { path: '/stationDetail/:id', name: 'Station', component: StationDetail }

  
];

export default routes;
