import React, { useEffect, useState } from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useKeycloak } from '@react-keycloak/web';
import { useAuth } from '../context/auth';
import axios from 'axios';
import { REACT_APP_API_BACKEND } from '../data/data';

const TheHeaderDropdownClient = () => {
  const { keycloak } = useKeycloak();
  const { userAuth, setUserAuth } = useAuth();
  const [currentClient, setCurrentClient] = useState();
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    axios.post(`${REACT_APP_API_BACKEND}/Usuario/clients?UserID=${userAuth.userID}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    })
      .then(res => {
        if (res.data.length > 1) {
          const currentClientName = res.data.filter(e => e.clientID == userAuth.clientID)[0].name;
          setClientList(res.data);
          setCurrentClient({
            clientID: userAuth.clientID,
            clientName: currentClientName
          });
        }
      })
      .catch(err => {
        console.log("Erro obter clients do servidor" + err);
      });
  }, []);

  function handleCurrentClient(currentClientID, currentClientName) {

    setCurrentClient({
      clientID: currentClientID,
      clientName: currentClientName
    });

    setUserAuth({
      nome: userAuth.nome,
      sobrenome: userAuth.sobrenome,
      email: userAuth.email,
      perfilID: null,
      userID: userAuth.userID,
      refreshToken: userAuth.refreshToken,
      token: userAuth.token,
      clientID: currentClientID,
      clientName: currentClientName,
      reloadMenu: true,
      accessToken: {
        headers: {
          Authorization: 'Bearer ' + keycloak.token
        }
      }
    })

    window.location.href = "#/"

  }

  return (
    <>
      {clientList.length > 1 &&
        <CDropdown
          inNav
          className="c-header-nav-items mx-2"
          direction="down"
        >
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            {currentClient?.clientName}
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem
              header
              tag="div"
              color="light"
              className="text-center"
            >
              <strong>Clientes</strong>
            </CDropdownItem>
            {clientList?.map(item => (
              <CDropdownItem onClick={() => handleCurrentClient(item.clientID, item.name)} >
                <CIcon name="cil-user" className="mfe-2" />{item.name}
              </CDropdownItem>
            ))}
          </CDropdownMenu>
        </CDropdown>
      }
    </>
  )
}

export default TheHeaderDropdownClient
