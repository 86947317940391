import React, { useContext, useState } from 'react'
import { isLoggedIn } from 'src/auth';
import { useKeycloak } from '@react-keycloak/web';

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [userAuth, setUserAuth] = useState();
    const [userNotification, setUserNotification] = useState([]);

    const [ativo, setAtivo] = useState({
        tagNum: '',
        tagDesc: '',
        tagID: 0,
        ativoID: 0,
        status: '',
        fabricante: '',
        modelo: '',
        ativo: false,
        tipoAtivo: '',
        numeroSerie: '',
    });

    return (
        <AuthContext.Provider value={{ userAuth, setUserAuth, ativo, setAtivo, userNotification, setUserNotification }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);
    let { userAuth, setUserAuth } = context;

    const { keycloak } = useKeycloak();
    const { authenticated } = keycloak;

    if (userAuth == undefined && authenticated) {
        userAuth = {
            nome: keycloak.idTokenParsed.given_name,
            sobrenome: keycloak.idTokenParsed.family_name,
            email: keycloak.idTokenParsed.email,
            perfilID: null,
            userID: keycloak.idTokenParsed.userID,
            refreshToken: keycloak.refreshToken,
            token: keycloak.token,
            clientID: keycloak.idTokenParsed.clientID,
            reloadMenu: false,
            accessToken: {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token
                }
            }
        };
    }

    return { userAuth, setUserAuth };
}

export function useAtivo() {
    const context = useContext(AuthContext);
    const { ativo, setAtivo } = context;

    return { ativo, setAtivo };
}

export function useNotification() {
    const context = useContext(AuthContext);
    const { userNotification, setUserNotification } = context;

    return { userNotification, setUserNotification };
}