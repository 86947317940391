import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { useAuth } from '../context/auth';

// sidebar nav config
import navigation from './_nav'
import navigationSysMenu from './_navSysMenu'
import logoAlpha from '../assets/icons/7.png'
import { REACT_APP_API_BACKEND } from '../data/data';

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const [navigationState, setNavigationState] = useState();
  const { userAuth } = useAuth();
  const [, setState] = React.useState(false);

  const forceReactMemoRerender = () => setState(x => !x);

  useEffect(() => {
    if (navigationSysMenu.length == 2 || userAuth.reloadMenu == true) {
      navigationSysMenu.splice(1);
      fetch(`${REACT_APP_API_BACKEND}/user/menus?userID=${userAuth.userID}&clientID=${userAuth.clientID}`, userAuth.accessToken)
        .then(resposta => resposta.json())
        .then(menus => {
          if (menus.length > 0) {
            menus.forEach(function (menu) {
              menu = { ...menu, name: menu.name, to: menu.route, _tag: 'CSidebarNavDropdown', icon: 'cil-cursor', _children: [] };
              menu.parentMenus.forEach(function (parent) {
                menu._children.push({ name: parent.name, to: parent.route, _tag: 'CSidebarNavItem' });
              });
              navigationSysMenu.push(menu);
            });
            setNavigationState(navigationSysMenu);
            forceReactMemoRerender();
          }
          else {
            setNavigationState(navigation);
          }
        });
    }
  }, [userAuth.clientID])

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
        <img style={{ height: 30 }} src={logoAlpha} />
        {/* <label style={{ paddingLeft:7, paddingTop: 7, fontSize: 18, fontStyle: 'italic' }} className="c-sidebar-brand-full">Alpha Manager</label> */}
      </CSidebarBrand>

      <CSidebarNav>
        <CCreateElement
          items={navigationState}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
