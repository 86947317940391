import React from 'react';
import { Container, Col } from 'react-bootstrap'
import { CRow } from '@coreui/react'

function Loading(props) {
    //console.log(props);
    if(props.isLoading === false) return null
    return (
        <Container>
            <CRow>
                <Col xs="12" sm="12" md="12">
                    <p style={{ textAlign: 'center', fontSize: '0.9rem', paddingTop: '50px' }}>
                        Buscando registros, por favor aguarde...
                    </p>
                </Col>
                <Col xs="12" sm="12" md="12" style={{ textAlign: 'center' }}>
                    <img src={"loading.gif"} alt="Loading..." style={{ width: 50 }} />
                </Col>
            </CRow>
        </Container>
    );
};

export default Loading;
