import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import axios from 'axios';
import iconFault from '../assets/icons/fault.png'
import iconWarning from '../assets/icons/warning.png'
import iconChecked from '../assets/icons/checked_ok.png'

import { useAuth, useNotification } from '../context/auth';
import Notificacao from '../components/notificacao/Notificacao';
import { REACT_APP_API_BACKEND, SERVER_SOCKET_LOCAL, SOCKET_TOPIC_ENCODER } from '../data/data';
import { io } from "socket.io-client";

const TheHeaderDropdownNotif = () => {
  const itemsCount = 3;
  let manipulatedValues = [];
  const msgNotificacao = itemsCount > 1 ? "notificações" : "notificação";

  const { userAuth } = useAuth();
  const { userNotification, setUserNotification } = useNotification();
  const [listValues, setListValues] = useState([]);
  const [hasNotification, setHasNotification] = useState([]);
  const [listNotificacao, setListNotificacao] = useState([]);
  const [contextApiUpdate, setContextApiUpdate] = useState([]);

  useEffect(() => {
    axios.get(`${REACT_APP_API_BACKEND}/Notification/User?userID=${userAuth.userID}&clientID=${userAuth.clientID}`, userAuth.accessToken)
    .then(res => {
      //console.log("res.data", res.data)
      setListNotificacao(res.data);
    })
    .catch(err => {
      //return null
    })
  }, [hasNotification, userAuth.clientID])

  useEffect(() => {
    setUserNotification(contextApiUpdate);
  }, [contextApiUpdate])


  useEffect(() => {
    localStorage.removeItem('efw-notification')
    const socket = io(SERVER_SOCKET_LOCAL);
    const topicAndUserInfo = {
        topicName: "AssetManageament",
        tokenUser: userAuth.token,
    };
    const topicAndUserInfoParsed = JSON.stringify(topicAndUserInfo);
    socket.emit("infoSettings", topicAndUserInfoParsed);
    socket.on("Encoders", (message) => {
        const data = JSON.parse(message);

        let hasNewEvent = data.filter(x => x.tagID == "newEvent" && x.clientID == userAuth.clientID);
        if(hasNewEvent.length > 0) {
          setHasNotification(new Date())
        }

        let notificationNew = data.map(x => Object.assign({}, { tagID: x.tagID, assetTypeID: x.assetTypeID, desc: x.desc, activated: x.activated}));
        let notificationOld = localStorage.getItem('efw-notification');
        if(notificationOld !== JSON.stringify(notificationNew)){
          setUserNotification(data);
          localStorage.removeItem('efw-notification')
          localStorage.setItem('efw-notification', JSON.stringify(notificationNew))
        }
    });

    socket.on("InvalidTokenUser", (message) => {
        socket.disconnect();
    });

    return () => {
        socket.removeAllListeners("Encoders");
        socket.disconnect();
    };
  }, []);

  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell" />
        <CBadge shape="pill" color="danger">{listNotificacao.length}</CBadge>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0">
        <CDropdownItem
          header
          tag="div"
          className="text-center"
          color="light"
        >
          <strong>Você tem {listNotificacao.length} {msgNotificacao}</strong>
        </CDropdownItem>

        <Notificacao list={listNotificacao} onClickNotify={{func: setHasNotification}}/>

      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownNotif