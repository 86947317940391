// //export const REACT_APP_API_BACKEND = 'http://localhost:5000';
// export const REACT_APP_API_BACKEND = 'https://api.alphamanager.com.br'
// //export const REACT_APP_API_BACKEND_ML = 'http://localhost:3004'
// export const REACT_APP_API_BACKEND_ML = 'https://api-machinelearning.alphamanager.com.br'
// //export const REACT_APP_API_DATASAVE = 'http://localhost:3002';
// //export const REACT_APP_API_DATASAVE = 'http://localhost:3002';
// export const REACT_APP_API_DATASAVE = 'https://api-datasave.alphamanager.com.br'
// export const API_BACKEND_ENCODER_ITEM = '/Encoders/encoder/';
// export const API_BACKEND_PARAMETROS_HOME = '/Asset/AssetParam?areaID=1&priority=1';
// export const API_BACKEND_ASSET_LIST = '/Asset/List';
// //export const SERVER_SOCKET_LOCAL = 'http://localhost:3000';
// //export const SERVER_SOCKET_LOCAL = 'http://ec2-3-20-182-100.us-east-2.compute.amazonaws.com:3000';
// export const SERVER_SOCKET_LOCAL = 'https://socket.alphamanager.com.br';
// export const API_BACKEND_UPS_ITEM = '/Ups/ups?upsID=';
// export const SOCKET_TOPIC_ENCODER = "KlabinEncodersMP7";
// export const ASSET_TYPE = {
//     Motor: 1,
//     Inversor: 2,
//     Encoder: 3,
//     Ups: 4,
//     TG: 5,
//     Precipitador: 6,
//     RedeProfibus: 7,
//     CCM: 8,
//     Prensa: 9,
//     Trafo: 10,
//     Disjuntor: 11
// }
// export const GetAssetTypeName = (assetTypeID) => {
//     switch (assetTypeID) {
//         case ASSET_TYPE.Inversor: return "Inversor";
//             break;
//         case ASSET_TYPE.Motor: return "Motor";
//             break;
//         case ASSET_TYPE.Ups: return "Ups";
//             break;
//         case ASSET_TYPE.Encoder: return "Encoder";
//             break;
//         case ASSET_TYPE.TG: return "Turbo Gerador";
//             break;
//         case ASSET_TYPE.Precipitador: return "Precipitador";
//             break;
//         case ASSET_TYPE.RedeProfibus: return "Rede Profibus";
//             break;
//         case ASSET_TYPE.CCM: return "CCM";
//             break;
//         case ASSET_TYPE.Prensa: return "Prensa";
//             break;
//         case ASSET_TYPE.Trafo: return "Trafo";
//             break;
//         case ASSET_TYPE.Disjuntor: return "Disjuntor";
//             break;
//         default: return "Ativo não cadastrado"
//     }
// }


// export const REACT_APP_KEYCLOAK_JSON = {
//     "realm": "Alpha Manager",
//     //"auth-server-url": "http://3.20.182.100:8080/auth/",
//     "auth-server-url": "https://acc.alphamanager.com.br/auth/",
//     "ssl-required": "none",
//     "resource": "react",
//     "public-client": true,
//     "confidential-port": 0
// }


export const REACT_APP_API_BACKEND = 'http://177.54.58.123:5000';
export const REACT_APP_API_BACKEND_ML = 'http://177.54.58.123:3004'
export const REACT_APP_API_DATASAVE = 'http://177.54.58.123:3002';
export const API_BACKEND_ENCODER_ITEM = '/Encoders/encoder/';
export const API_BACKEND_PARAMETROS_HOME = '/Asset/AssetParam?areaID=1&priority=1';
export const API_BACKEND_ASSET_LIST = '/Asset/List';
export const SERVER_SOCKET_LOCAL = 'http://177.54.58.123:3000';
export const API_BACKEND_UPS_ITEM = '/Ups/ups?upsID=';
export const SOCKET_TOPIC_ENCODER = "KlabinEncodersMP7";
export const ASSET_TYPE = {
    Motor: 1,
    Inversor: 2,
    Encoder: 3,
    Ups: 4,
    TG: 5,
    Precipitador: 6,
    RedeProfibus: 7,
    CCM: 8,
    Prensa: 9,
    Trafo: 10,
    Disjuntor: 11,
    Station: 12,
    Bomba: 13,
    

}
export const GetAssetTypeName = (assetTypeID) => {
    switch (assetTypeID) {
        case ASSET_TYPE.Inversor: return "Inversor";
            break;
        case ASSET_TYPE.Motor: return "Motor";
            break;
        case ASSET_TYPE.Ups: return "Ups";
            break;
        case ASSET_TYPE.Encoder: return "Encoder";
            break;
        case ASSET_TYPE.TG: return "Turbo Gerador";
            break;
        case ASSET_TYPE.Precipitador: return "Precipitador";
            break;
        case ASSET_TYPE.RedeProfibus: return "Rede Profibus";
            break;
        case ASSET_TYPE.CCM: return "CCM";
            break;
        case ASSET_TYPE.Prensa: return "Prensa";
            break;
        case ASSET_TYPE.Trafo: return "Trafo";
            break;
        case ASSET_TYPE.Disjuntor: return "Disjuntor";
            break;
        case ASSET_TYPE.Station: return "Station";
            break;
        case ASSET_TYPE.Bomba: return "Bomba";
            break;
        default: return "Ativo não cadastrado"
    }
}


export const REACT_APP_KEYCLOAK_JSON = {
    "realm": "Alpha Manager",
    //"auth-server-url": "http://3.20.182.100:8080/auth/",
    //"auth-server-url": "https://acc.alphamanager.com.br/auth/",
    "auth-server-url": "http://177.54.58.123:8080/auth/",
    "ssl-required": "none",
    "resource": "react",
    "public-client": true,
    "confidential-port": 0
}
