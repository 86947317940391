import { useKeycloak } from '@react-keycloak/web';

import React from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';

//import Menu from '../components/Menu';
//import HomePage from '../pages/HomePage';
import { PrivateRoute } from './utils/PrivateRoute';
//import ProtectedPage from '../pages/ProtectedPage';
import { TheLayout } from './containers';
//import Login from './views/pages/login/Login';
import Loading from './components/Loading/Loading';
const Login = React.lazy(() => import('./views/pages/login/Login'));

//const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const AppRouter = ({ props }) => {

    const { initialized } = useKeycloak();
        
    if (!initialized) {
        return (
            <div>
                <Loading />
            </div>);
    }

    return (
        <>
            <BrowserRouter>
                <HashRouter>
                    <React.Suspense>
                        <Switch>
                            <PrivateRoute roles={['default-roles-alpha manager']} path="/" name="Home" component={props => <TheLayout {...props} />} />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </BrowserRouter>
        </>
    );
};

export default AppRouter;