import React from 'react'
import CIcon from '@coreui/icons-react'

const _navSysMenu =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Home',
    to: '/home',
    icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon"/>,
    badge: {
      color: 'success',
      text: 'NEW',
    },
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Gerenciamento de Ativos'],
    name: 'CustomUserMenu'
  },
]

export default _navSysMenu
