import Keycloak from 'keycloak-js'
import { REACT_APP_KEYCLOAK_JSON } from './data/data';
// const keycloakConfig = {
//     url: 'http://localhost:8080/auth',
//     realm: 'Alpha Manager',
//     clientId: 'KlabinMA',
//     onLoad: 'check-sso'

// }
// const keycloak = new Keycloak(keycloakConfig);

const keycloakConfig = REACT_APP_KEYCLOAK_JSON;
export const keycloak = Keycloak({
    url: keycloakConfig['auth-server-url'],
    realm: keycloakConfig['realm'],
    clientId: keycloakConfig['resource']
});

//login-required ou check-sso
export const keycloakInitConfig = {
    onLoad: 'login-required'
}