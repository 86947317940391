import React from 'react';
import { Container, Col } from 'react-bootstrap'
import {
    CBadge,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CProgress,
    CDataTable,
    CCol,
    CRow,
} from '@coreui/react'

import iconWarning from '../../assets/icons/warning.png';
import iconChecked from '../../assets/icons/checked_ok.png';
import CIcon from '@coreui/icons-react'

import { useAuth } from '../../context/auth';
import axios from 'axios';
import { REACT_APP_API_BACKEND } from '../../data/data';


const Notificacao = ({ list, onClickNotify }) => {
    const { userAuth } = useAuth();
    const array = [];

    if (list.length > 5) {
        for (var i = 0; i < 5; i++) {
            array.push(list[i]);
        }
    }
    else {
        let count = list.length;

        for (var i = 0; i < count; i++) {
            array.push(list[i]);
        }
    }

    const checkNotification = (notificacaoID, readAll) => {
        //falta fazer o reconhecimento em massa de notificação (as ultimas 5)
        axios.post(`${REACT_APP_API_BACKEND}/Notification/Read?assetEventID=${notificacaoID}&userID=${userAuth.userID}&allAck=${readAll}`, userAuth.accessToken)
            .then(res => {
                console.log("Notificação confirmada: " + res.data);
                onClickNotify.func(new Date())
            })
            .catch(err => {
                console.log("Erro na confirmação de leitura da notificação");
            })
    }

    return (
        <>
            {array.map(lista => (
                <div key={lista.id} onClick={() => checkNotification(lista.assetEventID, false)}>

                    <CDropdownItem>
                        <div style={{ paddingRight: 10 }}>
                            {lista.eventDesc != undefined && lista.eventDesc.substr(0, 1) === '+' ?
                                <img height={18} src={iconWarning} />
                                :
                                <img height={18} src={iconChecked} />
                            }
                            {/* <label style={{ fontSize: 12, fontWeight: 'bold', paddingLeft: 5 }}>Inversor</label> */}
                            <label style={{ fontSize: 12, fontWeight: 'bold', paddingLeft: 5 }}>{lista.eventDesc}</label>
                            <label style={{ fontSize: 12, paddingLeft: 5 }}>{lista.tagName}</label>
                            <label style={{ fontSize: 11, opacity: 0.6, paddingLeft: 5 }}>{lista.assetTypeDesc}</label>
                            <label style={{ fontSize: 11, opacity: 0.6, paddingLeft: 5 }}>{lista.assetDesc1}</label>
                            <label style={{ fontSize: 11, opacity: 0.6, paddingLeft: 5 }}>{lista.assetDesc2}</label>
                        </div>
                        <div style={{ textAlign: 'end', paddingTop: 20 }}>
                            <label style={{ fontSize: 11, opacity: 0.6, paddingLeft: 5 }}>{lista.eventDate}</label>
                        </div>
                    </CDropdownItem>

                </div>
            ))}
            <CDropdownItem
                header
                tag="div"
                color="light"
            >
                <strong>Ações</strong>
            </CDropdownItem>
            <CDropdownItem onClick={() => checkNotification(0, true)} className="d-block">
                <div style={{ opacity: 0.8, textAlign: 'center' }}>
                    <CIcon name="cil-task" className="mfe-2" />
                    <small><b>Reconhecer as últimas notificações</b></small>
                </div>
            </CDropdownItem>

        </>
    );
};

export default Notificacao;



