import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../context/auth';

//const Login = React.lazy(() => import('./views/pages/login/Login'));

export function PrivateRoute({ component: Component, roles, ...rest }) {
    const { userAuth, setUserAuth } = useAuth();
    const { keycloak } = useKeycloak();
    const { authenticated } = keycloak;

    // const setUser = React.useCallback(() => {
    //     setUserAuth({
    //         nome: keycloak.idTokenParsed.given_name,
    //         sobrenome: keycloak.idTokenParsed.family_name,
    //         email: keycloak.idTokenParsed.email,
    //         perfilID: null,
    //         userID: keycloak.idTokenParsed.sub,
    //         refreshToken: keycloak.refreshToken,
    //         token: keycloak.token,
    //         clientID: '70050915-19B7-420B-9831-D7E304290997'
    //       });
    //   }, []);
    
    // if(authenticated){
    //   console.log('mateus aqui')
    //     if(userAuth == undefined){
    //         setUser();
    //     }
    // }
    const isAutherized = (roles) => {
        if (keycloak && roles) {
            return roles.some(r => {
                const realm =  keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    }

    return (
        <Route
            {...rest}
            render={props => {
                return isAutherized(roles)
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/', }} />
            }}
        />
      //   <Route
      //   {...rest}
      //   render={props => authenticated
      //     ? <Component {...props} />
      //     : <Redirect
      //       to={{
      //         pathname: '/login',
      //         //state: { from: props.location },
      //       }}
      //     />}
      // />
    )
}