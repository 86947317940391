import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Home',
    //to: '/dashboard',
    icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon"/>,
    badge: {
      color: 'info',
      //text: 'NEW',
    },
    addLinkClass: 'c-disabled',
    'disabled': true
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Gerenciamento de Ativos'],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Ativos',
    route: '/inversores/Inversores',
    icon: 'cil-cursor',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Carregando ...',
        to: '/inversores',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Encoders _',
      //   to: '/encoders',
      // },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Ups _',
      //   to: '/ups',
      // },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Indicadores _',
      //   //to: '/login',
      //   badge: {
      //     color: 'secondary',
      //    // text: 'NEW',
      //   },
      //   addLinkClass: 'c-disabled',
      //   'disabled': true
      // },
    ],
  },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Configurações'],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Usuários',
  //   icon: 'cil-ban',
  //   badge: {
  //     color: 'secondary',
  //    // text: 'NEW',
  //   },
  //   addLinkClass: 'c-disabled',
  //   'disabled': true
  // },
  // {
  //   _tag: 'CSidebarNavDivider', 
  //   className: 'm-2'
  // },
]

export default _nav
